const { REACT_APP_API_URL } = process.env;

export async function load({ sort, market = '' }) {
  let controller = new AbortController();
  if (market) {
    market = `/${market}`;
  }
  controller.data = await fetch(`${REACT_APP_API_URL}/positions${market}?sort=${sort}`, { signal: controller.signal }).then(res => {
    controller.sort = (res.headers.get('X-Sort') || '').split(',');
    return res.json();
  });
  return controller;
}
