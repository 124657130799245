import { Component } from 'react';
import Chart from './Chart2';
import Positions from './Positions';
import './App.css';

export default class App extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      market: window.location.pathname.substring(1) || 'BTC_BUSD',
      query: {
        interval: '30m',
        from: new Date(Date.now() - 30 * 24 * 3600 * 1000).toISOString(),
        to: new Date().toISOString(),
      },
    };
    window.location.search
      .substring(1)
      .split("&")
      .forEach((item) => {
        if (item !== '') {
          let [key, val] = item.split('=');
          if (['true', 'false'].includes(val)) {
            val = val === 'true';
          }
          this.state.query[key] = val;
        }
      });
    this.onPushState(undefined, undefined, true);
  }

  onPushState(newMarket, newQuery = {}, ignoreState) {
    const market = newMarket || this.state.market;
    const query = this.state.query;
    Object.entries(newQuery).forEach(([key, val]) => query[key] = val);
    if (!ignoreState) {
      this.setState({ market, query });
    }
    const queryString = Object.entries(this.state.query).map(([key, val]) => `${key}=${val}`).join('&');
    window.history.pushState('', '', `/${market}?${queryString}`);
  }

  onSelectPosition(pos) {
    this.setState({ pos });
  }


  render() {
    return (
      <div className="App">
        <Positions market={this.state.query.currentMarketOnly ? this.state.market : undefined} selected={this.state.query.position} sort={this.state.query.sort || 'id'} onPushState={this.onPushState.bind(this)} onSelect={this.onSelectPosition.bind(this)} currentMarketOnly={this.state.query.currentMarketOnly} />
        <Chart market={this.state.market} interval={this.state.query.interval} from={this.state.query.from} to={this.state.query.to} position={this.state.query.position} indicators={this.state.query.indicators} pos={this.state.pos} onPushState={this.onPushState.bind(this)} />
      </div>
    );
  }
}
