import { useEffect, useRef } from 'react';
import { init } from './lib/chart.js';
import './Chart.css';

export default function Chart({ market, interval, from, to, position, indicators, pos, onPushState }) {
  const onIntervalChange = el => {
    onPushState(undefined, { interval: el.currentTarget.innerText });
  }

  const onIndicatorsChange = el => {
    onPushState(undefined, { indicators: el.currentTarget.value });
  }

  const chartRef = useRef(null);
  const cleanupRef = useRef((count = 1) => {
    if (count > 500) {
      return console.error('Cleanup took too long!');
    }
    if (!chartRef.current) {
      return setTimeout(() => cleanupRef.current(count + 1), 10);
    }
    chartRef.current.remove();
    chartRef.current = null;
  });

  useEffect(() => {
    if (position && !pos) {
      return;
    }
    init({ market, interval, from, to, position, pos, indicators }).then(chart => {
      chartRef.current = chart
    });
    return cleanupRef.current;
  }, [market, interval, from, to, position, pos, indicators]);

  return (
    <main>
      <div id="switcher">
        <button className={'switcher-item' + (interval === '1m' ? ' active' : '')} onClick={onIntervalChange}>1m</button>
        <button className={'switcher-item' + (interval === '5m' ? ' active' : '')} onClick={onIntervalChange}>5m</button>
        <button className={'switcher-item' + (interval === '15m' ? ' active' : '')} onClick={onIntervalChange}>15m</button>
        <button className={'switcher-item' + (interval === '30m' ? ' active' : '')} onClick={onIntervalChange}>30m</button>
        <button className={'switcher-item' + (interval === '1h' ? ' active' : '')} onClick={onIntervalChange}>1h</button>
        <button className={'switcher-item' + (interval === '4h' ? ' active' : '')} onClick={onIntervalChange}>4h</button>
      </div>
      <div id="indicators">
        <label htmlFor="indicatorsInput">Indicators</label> <input type="text" id="indicatorsInput" value={indicators || ''} onChange={onIndicatorsChange} />
      </div>
      <div id="chart" data-interval={interval}>
        <div className="legend">
          <div id="marketLegend">{market.replace(/_/, ' / ')}</div>
          <div id="volumeLegend">Vol</div>
          <div id="maLegend">MA</div>
          <div id="rsiLegend">RSI</div>
        </div>
      </div>
    </main>
  );
}
