import React, { Component } from 'react';
import Box from '@mui/material/Box';
// import Item from '@mui/material/MenuItem';
// import Grid from '@mui/material/Grid';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
// import Stack from '@mui/material/Stack'; // Grid version 2
import { DataGrid } from '@mui/x-data-grid';
// import './Followings.css';
import { load, set } from './lib/followings.js';
import { load as loadCategories } from './lib/categories.js';

export default class App extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      query: {
        size: 50,
        page: 1,
        sort: '-followed_at',
      },
    };

    // this.theme = createTheme({
    //   components: {
    //     MuiStack: {
    //       defaultProps: {
    //         useFlexGap: true,
    //       },
    //     },
    //   },
    // });

    this.columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: true,
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
      },
      {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 110,
        editable: true,
      },
      {
        field: 'fullName',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
          `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      },
    ];
    this.rows = [
      { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
      { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
      { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
      { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
      { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
      { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
      { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
      { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
      { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    window.location.search
      .substring(1)
      .split("&")
      .forEach((item) => {
        if (item !== '') {
          let [key, val] = item.split('=');
          if (['true', 'false'].includes(val)) {
            val = val === 'true';
          }
          if (key.includes('[')) {
            let prop;
            [key, prop] = key.substring(0, key.length - 1).split('[');
            const foo = this.state.query[key] || {};
            foo[prop] = val;
            val = foo;
          }
          this.state.query[key] = val;
        }
      });
    this.onPushState(undefined, true);
  }

  // componentDidMount() {
  //   this.load();
  // }

  onPushState(newQuery = {}, ignoreState) {
    // const query = this.state.query || {};
    // Object.entries(newQuery).forEach(([key, val]) => query[key] = val);
    if (!ignoreState) {
      // this.setState({ query });
      this.setState(prevState => ({ ...prevState.query, newQuery }));
    }
    const queryString = [];
    Object.entries(this.state.query).forEach(([key, val]) => {
      if (typeof val === 'object') {
        Object.entries(val).forEach(([key2, val2]) => queryString.push(`${key}[${key2}]=${val2}`));
      } else {
        queryString.push(`${key}=${val}`);
      }
    });
    window.history.pushState('', '', `${window.location.pathname}?${queryString.join('&')}`);
  }

  render() {
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          columns={this.columns}
          rows={this.rows}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          // pageSizeOptions={[5]}
          // checkboxSelection
          // disableRowSelectionOnClick
        />
      </Box>
    );    // return (
    //   <section className="Accounts">
    //     <h1>WhatTheChain?! (pre-alpha)</h1>
    //     <h2>Accounts</h2>
    //
    //     <ul>
    //       <a href="">link1</a>
    //       <a href="">link2</a>
    //       <a href="">link3</a>
    //     </ul>
    //   </section>
    // );
  }
}
