import { createChart } from 'lightweight-charts';

const { REACT_APP_API_URL } = process.env;

export async function init({ market, interval, from, to, pos, price = 'candles', indicators = '' }) {
  const [base, quote] = market.split('_');
  indicators = indicators.split(','); //.map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`);
  const width = window.innerWidth - 225;
  const height = window.innerHeight - 140;

  // const chart = createChart('chart', { width: 400, height: 300 });
  const chart = createChart('chart', {
    width,
    height,
    // timeScale2: {
    //   tickMarkFormatter: (time) => {
    //     const date = new Date(time.year, time.month, time.day);
    //     return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    //   },
    // },
    timeScale: {
      timeVisible: true,
      borderColor: '#D1D4DC',
    },
    leftPriceScale: {
      visible: true,
      borderColor: '#D1D4DC',
    },
    rightPriceScale: {
      borderColor: '#D1D4DC',
      scaleMargins: {
        top: 0.01,
        bottom: 0.01,
      },
    },
    // layout: {
    //   backgroundColor: '#ffffff',
    //   textColor: '#000',
    // },
    grid: {
      horzLines: {
        color: '#F0F3FA',
      },
      vertLines: {
        color: '#F0F3FA',
      },
    },
  });

  let data = [];
  try {
    data = await getCandles(base, quote, interval, from, to, indicators)
  } catch (e) {
    throw e;
  }

  if (data.length === 0) {
    return console.log('No data');
  }

  const { positionIndex } = price === 'candles' ? addPriceCandles(chart, data, pos, indicators) : addPriceLine(chart, data, pos);
  addVolume(chart, data);
  if (indicators.includes('sma')) {
    addSMA(chart, data);
  }
  if (indicators.includes('rsi')) {
    addRSI(chart, data);
  }
  if (indicators.includes('stochRSI')) {
    addStochRSI(chart, data);
  }
  if (indicators.includes('bollinger')) {
    addBollinger(chart, data);
  }
  if (indicators.includes('tdSequential')) {
    addTDSequentialLines(chart, data);
  }
  if (indicators.includes('extrema')) {
    addSupportResistance(chart, data);
  }
  addTooltip(chart, base, quote, width, height);

  if (pos) {
    chart.timeScale().scrollToPosition(-positionIndex + 50, false);
  } else {
    chart.timeScale().fitContent();
  }

  return chart;
}

// export async function update(chart, { market, interval, from, to, position }) {
//   // console.log('---chart.update', chart, { market, interval, from, to, position })
//   // return chart.timeScale().fitContent();
//   const [base, quote] = market.split('_');
//   const data = await getCandles(base, quote, interval, from, to);
//   series.setData(data);
//   // series.setMarkers(markers);
//   series.applyOptions({
//     priceFormat: {
//       type: 'price',
//       precision: 8,
//       minMove: 0.00000001,
//     },
//   });
// }

async function getCandles(base, quote, interval, from, to, indicators) {
  const time = `?from=${from}&to=${to}&interval=${interval}&indicators=${indicators.join(',')}`;
  const candles = await fetch(`${REACT_APP_API_URL}/markets/${base}_${quote}${time}`).then(res => {
    if (res.ok) return res.json();
    const err = new Error('Error getting candles');
    err.payload = res;
    throw err;
  });

  return candles.map(candle => {
    candle.humanTime = candle.time;
    candle.time = Math.floor(new Date(candle.time).getTime() / 1000);
    return candle;
  });
}

function addPriceLine(chart, data) {
  const series = chart.addLineSeries({
    color: 'rgba(0, 0, 0, 1)',
    lineWidth: 1,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  series.setData(data.map(({ time, close }) => ({ time, value: close })));
  return { series, positionIndex: 0 };
}

let series;

function addPriceCandles(chart, data, position, indicators) {
  series = chart.addCandlestickSeries({
    upColor: 'rgb(38,166,154)',
    downColor: 'rgb(255,82,82)',
    wickUpColor: 'rgb(38,166,154)',
    wickDownColor: 'rgb(255,82,82)',
  });

  const markers = [];
  let positionIndex = 0;
  if (position) {
    let openedAt = new Date(position.opened_at);
    let closedAt = new Date(position.closed_at);

    const tsData = JSON.parse(JSON.stringify(data));
    let i = 0;
    tsData && tsData.reverse().forEach((d) => {
      if (positionIndex === 0 && d.time * 1000 < openedAt) {
        positionIndex = i;
      }
      i += 1;
    });

    markers.push(
      {
        time: new Date(openedAt).getTime() / 1000,
        humanDate: new Date(openedAt).toISOString(),
        position: 'aboveBar',
        color: '#2196F3',
        shape: 'arrowUp',
        text: `Buy @ ${position.price_entry}`,
      },
      {
        time: new Date(closedAt).getTime() / 1000,
        humanDate: new Date(closedAt).toISOString(),
        position: 'aboveBar',
        color: '#e91e63',
        shape: 'arrowDown',
        text: `Sell @ ${position.price_exit}`,
      },
    );
  }

  let extremaList = {};
  data.forEach(candle => {
    if (indicators.includes('tdSequential')) {
      const tds = candle.indicators.tdSequential;
      // Setups
      if (tds.buySetupIndex === 9) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'aboveBar',
          color: 'rgb(38,166,154)',
          shape: 'arrowUp',
        });
      }
      if (tds.buySetupIndex) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'aboveBar',
          color: 'rgb(255,82,82)',
          shape: tds.buySetupPerfection ? 'circle' : undefined, // tds.buySetup ? (tds.buySetupPerfection ? 'circle' : 'square') : undefined,
          text: tds.buySetupIndex.toString(),
        });
      }
      if (tds.sellSetupIndex === 9) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'aboveBar',
          color: 'rgb(255,82,82)',
          shape: 'arrowDown',
        });
      }
      if (tds.sellSetupIndex) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'aboveBar',
          color: 'rgb(38,166,154)',
          shape: tds.sellSetupPerfection ? 'circle' : undefined, // tds.sellSetup ? (tds.sellSetupPerfection ? 'circle' : 'square') : undefined,
          text: tds.sellSetupIndex.toString(),
        });
      }

      // Countdowns
      markers.push({
        time: candle.time,
        humanDate: new Date(candle.time).toISOString(),
        position: 'belowBar',
        color: 'rgb(255,82,82)',
        text: `${tds.buyCoundownIndex && !tds.countdownIndexIsEqualToPreviousElement ? tds.buyCoundownIndex : ''}`,
      });
      if (tds.buyCoundownIndex === 13) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'belowBar',
          color: 'rgb(38,166,154)',
          shape: 'arrowUp',
        });
      }
      markers.push({
        time: candle.time,
        humanDate: new Date(candle.time).toISOString(),
        position: 'belowBar',
        color: 'rgb(38,166,154)',
        text: `${tds.sellCoundownIndex && !tds.countdownIndexIsEqualToPreviousElement ? tds.sellCoundownIndex : ''}`,
      });
      if (tds.sellCoundownIndex === 13) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: 'belowBar',
          color: 'rgb(255,82,82)',
          shape: 'arrowDown',
        });
      }
    }

    if (indicators.includes('extrema')) {
      const extrema = candle.indicators ? candle.indicators.extrema : {};
      if (extrema.price && !extremaList[extrema.price.value]) {
        markers.push({
          time: candle.time,
          humanDate: new Date(candle.time).toISOString(),
          position: extrema.price.tests[0][1] === 'resistance' ? 'aboveBar' : 'belowBar',
          color: extrema.price.tests[0][1] === 'resistance' ? '#e91e63' : '#2196F3',
          shape: extrema.price.tests[0][1] === 'resistance' ? 'arrowDown' : 'arrowUp',
          text: `#${extrema.price.id} (${extrema.price.support}s/${extrema.price.resistance}r)`,
        });
        extremaList[extrema.price.value] = extrema;
      }
    }
  });

  markers.sort((a, b) => (a.time > b.time) ? 1 : -1)

  series.setData(data);
  series.setMarkers(markers);
  series.applyOptions({
    priceFormat: {
      type: 'price',
      precision: 8,
      minMove: 0.00000001,
    },
    lastValueVisible: false,
    priceLineVisible: false,
  });

  return { series, positionIndex };
}

function addVolume(chart, data) {
  const volumeSeries = chart.addHistogramSeries({
    priceFormat: { type: 'volume' },
    priceScaleId: 'Volume',
    // scaleMargins: { top: 0, bottom: 0 },
    lastValueVisible: false,
    priceLineVisible: false,
  });
  volumeSeries.priceScale().applyOptions({
    scaleMargins: { top: 0.9, bottom: 0 },
  });
  volumeSeries.setData(data.map(({ time, open, close, volume }) => ({ time, value: volume, color: close >= open ? '#26a69a' : '#ef5350' })));
  return volumeSeries;
}

function addSMA(chart, data) {
  let ma = {
    50: [],
    200: [],
  };
  data.forEach((m) => {
    if (m.indicators && m.indicators.sma) {
      if (m.indicators.sma['50']) {
        ma['50'].push({ time: m.time, value: m.indicators.sma['50']})
      }
      if (m.indicators.sma['200']) {
        ma['200'].push({ time: m.time, value: m.indicators.sma['200']})
      }
    }
  });

  const ma50 = chart.addLineSeries({
    color: 'rgba(255, 165, 0, 1)',
    lineWidth: 2,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  ma50.setData(ma['50']);

  const ma200 = chart.addLineSeries({
    color: 'rgba(138, 43, 226, 1)',
    lineWidth: 2,
    lastValueVisible: false,
    priceLineVisible: false,
  });
  ma200.setData(ma['200']);
  return { ma50, ma200 };
}

function addRSI(chart, data) {
  const rsi = chart.addLineSeries({
    color: 'rgba(4, 111, 232, 1)',
    lineWidth: 2,
    priceScaleId: 'left',
    lastValueVisible: false,
    priceLineVisible: false,
  });
  rsi.setData(data.map(({ time, indicators }) => {
    return { time, value: indicators && indicators.rsi && indicators.rsi.default ? indicators.rsi.default : undefined };
  }));
  return rsi;
}

function addStochRSI(chart, data) {
  const kVal = chart.addLineSeries({ color: 'rgba(0, 148, 255, 1)', lineWidth: 1, priceScaleId: 'left', lastValueVisible: false, priceLineVisible: false });
  const dVal = chart.addLineSeries({ color: 'rgba(255, 106, 0, 1)', lineWidth: 1, priceScaleId: 'left', lastValueVisible: false, priceLineVisible: false });
  kVal.setData(data.map(({ time, indicators }) => ({
    time, value: indicators && indicators.stochRSI && indicators.stochRSI.default ? indicators.stochRSI.default.k : undefined,
  })));
  dVal.setData(data.map(({ time, indicators }) => ({
    time, value: indicators && indicators.stochRSI && indicators.stochRSI.default ? indicators.stochRSI.default.d : undefined,
  })));
  return { kVal, dVal };
}

function addBollinger(chart, data) {
  const hVal = chart.addLineSeries({ color: 'rgba(138, 98, 255, 1)', lineWidth: 2, lastValueVisible: false, priceLineVisible: false });
  const lVal = chart.addLineSeries({ color: 'rgba(138, 98, 255, 1)', lineWidth: 2, lastValueVisible: false, priceLineVisible: false });
  const bVal = chart.addLineSeries({ color: 'rgba(138, 158, 255, 1)', lineWidth: 1, lastValueVisible: false, priceLineVisible: false });
  hVal.setData(data.map(({ time, indicators }) => ({
    time, value: indicators && indicators.bollinger && indicators.bollinger.default ? indicators.bollinger.default.upper : undefined,
  })));
  lVal.setData(data.map(({ time, indicators }) => ({
    time, value: indicators && indicators.bollinger && indicators.bollinger.default ? indicators.bollinger.default.lower : undefined,
  })));
  bVal.setData(data.map(({ time, indicators }) => ({
    time, value: indicators && indicators.bollinger && indicators.bollinger.default ? indicators.bollinger.default.middle : undefined,
  })));
  return { hVal, lVal, bVal };
}

function addTDSequentialLines(chart, data) {
  const tdstTrueHigh = chart.addBarSeries({ upColor: 'rgba(255,82,82, 0.1)', downColor: 'rgba(255,82,82, 0.1)', lastValueVisible: false, priceLineVisible: false });
  tdstTrueHigh.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTTrueHigh ? indicators.tdSequential.TDSTTrueHigh : undefined,
    high: indicators.tdSequential.TDSTTrueHigh ? indicators.tdSequential.TDSTTrueHigh : undefined,
    low: indicators.tdSequential.TDSTTrueHigh ? indicators.tdSequential.TDSTTrueHigh : undefined,
    close: indicators.tdSequential.TDSTTrueHigh ? indicators.tdSequential.TDSTTrueHigh : undefined,
  })));
  const tdstTrueLow = chart.addBarSeries({ upColor: 'rgba(38,166,154, 0.1)', downColor: 'rgba(38,166,154, 0.1)', lastValueVisible: false, priceLineVisible: false });
  tdstTrueLow.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTTrueLow ? indicators.tdSequential.TDSTTrueLow : undefined,
    high: indicators.tdSequential.TDSTTrueLow ? indicators.tdSequential.TDSTTrueLow : undefined,
    low: indicators.tdSequential.TDSTTrueLow ? indicators.tdSequential.TDSTTrueLow : undefined,
    close: indicators.tdSequential.TDSTTrueLow ? indicators.tdSequential.TDSTTrueLow : undefined,
  })));

  const tdstSupport = chart.addBarSeries({ upColor: 'rgba(38,166,154, 1)', downColor: 'rgba(38,166,154, 1)', lastValueVisible: false, priceLineVisible: false });
  tdstSupport.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTSupport ? indicators.tdSequential.TDSTSupport : undefined,
    high: indicators.tdSequential.TDSTSupport ? indicators.tdSequential.TDSTSupport : undefined,
    low: indicators.tdSequential.TDSTSupport ? indicators.tdSequential.TDSTSupport : undefined,
    close: indicators.tdSequential.TDSTSupport ? indicators.tdSequential.TDSTSupport : undefined,
  })));
  const tdstResistance = chart.addBarSeries({ upColor: 'rgba(255,82,82, 1)', downColor: 'rgba(255,82,82, 1)', lastValueVisible: false, priceLineVisible: false });
  tdstResistance.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTResistance ? indicators.tdSequential.TDSTResistance : undefined,
    high: indicators.tdSequential.TDSTResistance ? indicators.tdSequential.TDSTResistance : undefined,
    low: indicators.tdSequential.TDSTResistance ? indicators.tdSequential.TDSTResistance : undefined,
    close: indicators.tdSequential.TDSTResistance ? indicators.tdSequential.TDSTResistance : undefined,
  })));

  const tdstSupportRisk = chart.addBarSeries({ upColor: 'rgba(38,166,154, 0.3)', downColor: 'rgba(38,166,154, 0.3)', lastValueVisible: false, priceLineVisible: false });
  tdstSupportRisk.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTSupportRiskLevel ? indicators.tdSequential.TDSTSupportRiskLevel : undefined,
    high: indicators.tdSequential.TDSTSupportRiskLevel ? indicators.tdSequential.TDSTSupportRiskLevel : undefined,
    low: indicators.tdSequential.TDSTSupportRiskLevel ? indicators.tdSequential.TDSTSupportRiskLevel : undefined,
    close: indicators.tdSequential.TDSTSupportRiskLevel ? indicators.tdSequential.TDSTSupportRiskLevel : undefined,
  })));
  const tdstResistanceRisk = chart.addBarSeries({ upColor: 'rgba(255,82,82, 0.3)', downColor: 'rgba(255,82,82, 0.3)', lastValueVisible: false, priceLineVisible: false });
  tdstResistanceRisk.setData(data.map(({ time, indicators }) => ({
    time,
    open: indicators.tdSequential.TDSTResistanceRiskLevel ? indicators.tdSequential.TDSTResistanceRiskLevel : undefined,
    high: indicators.tdSequential.TDSTResistanceRiskLevel ? indicators.tdSequential.TDSTResistanceRiskLevel : undefined,
    low: indicators.tdSequential.TDSTResistanceRiskLevel ? indicators.tdSequential.TDSTResistanceRiskLevel : undefined,
    close: indicators.tdSequential.TDSTResistanceRiskLevel ? indicators.tdSequential.TDSTResistanceRiskLevel : undefined,
  })));

  return { tdstTrueHigh, tdstTrueLow, tdstSupport, tdstResistance, tdstSupportRisk, tdstResistanceRisk };
}

function addSupportResistance(chart, data) {
  // console.log('---extrema', data);
  const series = {};
  const levels = {};
  const current = {};
  const strongestExtrema = Math.max(...data.map(candle => candle.indicators.extrema.price ? candle.indicators.extrema.price.tests.length : 0));
  console.log('---strongestExtrema', strongestExtrema);
  // this.values.map(val => val.high - val.low).reduce((prev, curr) => prev + curr) / this.values.length;
  data.forEach(candle => {
    const extrema = candle.indicators.extrema.price;
    if (extrema) {
      const { value } = extrema; // extrema.tests[0][1] === 'support' ? candle.low : candle.high;
      if (!levels[value]) {
        // console.log('---#', extrema.id, color, visibility, value);
        series[value] = chart.addBarSeries({ upColor: 'rgba(200,200,200, 1)', downColor: 'rgba(200,200,200, 1)', lastValueVisible: false, priceLineVisible: false });
        // series[value] = chart.addLineSeries({ color, lineWidth: 1, lastValueVisible: false, priceLineVisible: false });
        levels[value] = [];
      } else {

      }
      const visibility = (extrema.tests.length / 10).toFixed(1);
      current[value] = {
        color: extrema.tests[extrema.tests.length - 1][1] === 'resistance' ? `rgba(255,0,0, ${visibility})` : `rgba(0,255,0, ${visibility})`,
      };
    }
    Object.entries(levels).forEach(([level, arr]) => arr.push({ time: candle.time, value: Number(level), current: current[level] || {} }));
  });
  // console.log(levels);
  Object.entries(series).forEach(([level, serie]) => {
    serie.setData(levels[level].map(({ time, value, current }) => {
      // console.log('---value', value, current);
      // const color = extrema ? extrema.tests[extrema.tests.length - 1][1] === 'resistance' ? `rgba(0,255,0, 1)` : `rgba(255,0,0, 1)` : undefined;
      return {
        time,
        open: value,
        high: value,
        low: value,
        close: value,
        // value,
        color: current.color,
        // color: Math.random() > 0.5 ? `rgba(0,255,0, 1)` : `rgba(255,0,0, 1)`,
      };
    }));
  });
}

function addTooltip(chart, base, quote, width, height, priceSeries) {
  const chartEl = document.getElementById('chart');
  const previous = chartEl.getElementsByClassName('floating-tooltip-2');
  while(previous[0]) {
    previous[0].parentNode.removeChild(previous[0]);
  }

  const toolTip = document.createElement('div');
  toolTip.className = 'floating-tooltip-2';
  chartEl.appendChild(toolTip);

  return chart.subscribeCrosshairMove(param => {
    const toolTipWidth = 120;
    const toolTipHeight = 120;
    const toolTipMargin = 50;

    if (!param.time || param.point.x < 0 || param.point.x > width || param.point.y < 0 || param.point.y > height) {
      toolTip.style.display = 'none';
      return;
    }
    // const dateStr = new Date(param.time * 1000).toISOString();

    toolTip.style.display = 'block';
    const [data, volume, ma50, ma200, rsi] = Array.from(param.seriesData.values());

    const y = param.point.y;
    const marketLegend = document.getElementById('marketLegend');
    let color = 'red';
    if (data.close > data.open) {
      color = 'green';
    }
    let diff = parseFloat(data.close - data.open).toFixed(8);
    let p = ((1 - (data.open / data.close))*100).toFixed(2);
    if (diff > 0) {
      diff = `<span class="positive">+${diff} ${p}%</span>`;
    } else {
      diff = `<span class="negative">${diff} ${p}%</span>`;
    }
    marketLegend.innerHTML = `${base} / ${quote} O <span style='color: ${color}'>${parseFloat(data.open).toFixed(8)}</span> H <span style='color: ${color}'>${parseFloat(data.high).toFixed(8)}</span> L <span style='color: ${color}'>${parseFloat(data.low).toFixed(8)}</span> C <span style='color: ${color}'>${parseFloat(data.close).toFixed(8)}</span> ${diff}`;

    let left = param.point.x;
    if (left > width - toolTipWidth) {
      left = param.point.x;
    }

    left = left + 250;

    let top = y + toolTipMargin;
    if (top > height - toolTipHeight) {
      top = y - toolTipHeight - toolTipMargin;
    }

    toolTip.innerHTML = `<div>
          <div style="font-size: 12px; margin: 4px 0">Volume ${base}: ${parseFloat( volume.value ).toFixed(8)}</div>
          <div style="font-size: 12px; margin: 4px 0">Volume ${quote}: ${parseFloat( volume.value * data.close ).toFixed(8)}</div>
          </div>`;

    toolTip.style.left = left + 'px';
    toolTip.style.top = top + 'px';

    if (ma50 || ma200) {
      const maLegend = document.getElementById('maLegend');
      maLegend.innerHTML = `SMA50 <span style="color: #ffa500">${Number(ma50 ? ma50.value : null).toFixed(8)}</span> SMA200 <span style="color: #8a2be2">${Number(ma200 ? ma200.value : null).toFixed(8)}</span>`;
    }
    if (volume) {
      const volumeLegend = document.getElementById('volumeLegend');
      volumeLegend.innerHTML = `Vol <span>${volume.value}</span>`;
    }
    if (rsi) {
      const rsiLegend = document.getElementById('rsiLegend');
      rsiLegend.innerHTML = `RSI <span style="color: #046fe8">${rsi.value}</span>`;
    }

  });
}
