import React, { Component } from 'react';
import './Followings.css';
import { load, set } from './lib/followings.js';
import { load as loadCategories } from './lib/categories.js';

export default class App extends Component {
  constructor(...args) {
    super(...args);

    this.filterList = ['follower', 'followee', 'flag', 'category', 'status', 'is_blue_verified', 'error', 'follower_id', 'followee_id'];
    this.state = {
      query: {
        size: 50,
        page: 1,
        sort: '-followed_at_min',
      },
    };
    window.location.search
      .substring(1)
      .split("&")
      .forEach((item) => {
        if (item !== '') {
          let [key, val] = item.split('=');
          if (['true', 'false'].includes(val)) {
            val = val === 'true';
          }
          if (key.includes('[')) {
            let prop;
            [key, prop] = key.substring(0, key.length - 1).split('[');
            const foo = this.state.query[key] || {};
            foo[prop] = val;
            val = foo;
          }
          this.state.query[key] = val;
        }
      });
    this.onPushState(undefined, true);
  }

  componentDidMount() {
    this.load();
  }

  onPushState(newQuery = {}, ignoreState) {
    const query = this.state.query;
    Object.entries(newQuery).forEach(([key, val]) => query[key] = val);
    if (!ignoreState) {
      this.setState({ query });
    }
    const queryString = [];
    Object.entries(this.state.query).forEach(([key, val]) => {
      if (typeof val === 'object') {
        Object.entries(val).forEach(([key2, val2]) => queryString.push(`${key}[${key2}]=${val2}`));
      } else {
        queryString.push(`${key}=${val}`);
      }
    });
    window.history.pushState('', '', `${window.location.pathname}?${queryString.join('&')}`);
  }

  async load() {
    this.categories = localStorage.getItem('categories');
    if (!this.categories) {
      this.request = await loadCategories();
      this.categories = this.request.data;
      localStorage.setItem('categories', JSON.stringify(this.categories));
    } else {
      this.categories = JSON.parse(this.categories);
    }
    this.request = await load();
    this.setState({ data: this.request.data, sort: this.request.sort });
    this.request = null;
  }

  async loadMore(){
    const page = Number(this.state.query.page) + 1;
    this.onPushState({ page });
    this.load();
  }

  async onChangeSortBy(el) {
    const sort = el.target.dataset.key;
    this.onPushState({ sort });
    this.load();
  }

  async onSelectProperty(pos) {
    const val = {};
    val[pos.target.dataset.prop] = pos.target.value || null;
    this.state.data[Number(pos.target.dataset.key)].flag = pos.target.value;
    this.setState({ data: this.state.data });

    // this.setState(prevState => ({
    //   ...prevState.data || {},
    //   data[Number(pos.target.dataset.key)].flag: pos.target.value,
    // }));

    try {
      this.request = await set(pos.target.dataset.id, val);
    } catch (e) {
      if (e.message !== 'Unauthorized') {
        throw e;
      }
      const username = prompt('Username');
      const password = prompt('Password');
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    }
    this.request = null;
  }

  onAddFilter() {
    const filter = this.filterList.find(item => !Object.keys(this.state.query.filter || {}).includes(item));
    const filters = this.state.query.filter || {};
    filters[filter] = '';
    this.setState({ query: this.state.query.filter = filters });
    this.setState({ query: this.state.query.page = 1 });
    this.onPushState({ filter: this.state.query.filter });
    this.load();
  }

  onRemoveFilter(el) {
    delete this.state.query.filter[el.target.dataset.key];
    this.setState({ query: this.state.query });
    this.setState({ query: this.state.query.page = 1 });
    this.onPushState({ filter: this.state.query.filter });
    this.load();
  }

  onSelectFilter(el) {
    const filter = this.state.query.filter[el.target.dataset.key];
    delete this.state.query.filter[el.target.dataset.key];
    this.state.query.filter[el.target.value] = filter;
    this.setState({ query: this.state.query });
    this.setState({ query: this.state.query.page = 1 });
    this.onPushState({ filter: this.state.query.filter });
    this.load();
  }

  onTypeFilter(el) {
    this.state.query.filter[el.target.dataset.key] = el.target.value;
    this.setState({ query: this.state.query });
    this.setState({ query: this.state.query.page = 1 });
    this.onPushState({ filter: this.state.query.filter });
    this.load();
  }

  renderFilter(key, k, v) {
    return <li key={key}>
      <select data-key={k} onChange={this.onSelectFilter.bind(this)} defaultValue={k}>
        <option value=""></option>
        {this.filterList.map((v2, i)  => <option key={i} value={v2}>{v2}</option>)}
      </select>
      <input data-key={k} value={v} onChange={this.onTypeFilter.bind(this)} />
      <button data-key={k} onClick={this.onRemoveFilter.bind(this)}>X</button>
    </li>;
  }

  renderFollowing(key, val) {
    return <tr key={key} className={val.flag}>
      <td title={'followed_at_max: ' + new Date(val.followed_at_max).toISOString().replace(/T/, ' ').substring(0, 19)}>
        {new Date(val.followed_at_min).toISOString().replace(/T/, ' ').substring(0, 19)}
      </td>
      <td className="flag">
        <select data-key={key} data-prop="flag" data-id={val.followee_id} onChange={this.onSelectProperty.bind(this)} defaultValue={val.flag}>
          <option value=""></option>
          <option value="irrelevant">Irrelevant</option>
          <option value="shitcoin">Shitcoin</option>
          <option value="project">Project</option>
          <option value="researcher">Researcher</option>
          <option value="investor">Investor</option>
          <option value="trader">Trader</option>
        </select>
      </td>
      <td className="category">
        <select data-key={key} data-prop="category" data-id={val.followee_id} onChange={this.onSelectProperty.bind(this)} defaultValue={val.category}>
          <option value=""></option>
          {this.categories.map(({ id, name }, i) => <option key={i} value={id}>{name}</option>)}
        </select>
      </td>
      <td className="status">
        <select data-key={key} data-prop="status" data-id={val.followee_id} onChange={this.onSelectProperty.bind(this)} defaultValue={val.status}>
          <option value=""></option>
          <option value="check">Check</option>
          <option value="followup">Followup</option>
          <option value="invest">Invest</option>
          <option value="done">Done</option>
        </select>
      </td>
      <td title={val.follower.length > 1 ? val.follower.join(', ') : undefined}>
        <a href={'https://twitter.com/' + val.follower[0]} target="twitter">{val.follower[0]}</a><br />
        {val.relevant_followers_count > 1 ? '+' + (val.relevant_followers_count - 1) + ' others' : undefined}
      </td>
      <td><a href={'https://twitter.com/' + val.followee} target="twitter" className="listImage"><img src={val.image} /></a></td>
      <td><a href={'https://twitter.com/' + val.followee} target="twitter">{val.followee}</a></td>
      <td>{val.name}</td>
      <td>{new Date(val.source_created_at).toISOString().substring(0, 10)}</td>
      <td>{val.relevant_followers_count}</td>
      <td>{val.followers_count}</td>
      <td>{val.description}</td>
    </tr>
  }

  renderTh(key, sortable, colSpan) {
    const current = this.state.query.sort.replace(/-/, '');
    const desc = this.state.query.sort.substring(0, 1) === '-';
    const next = desc ? key : `-${key}`;
    return sortable ? <th colSpan={colSpan}><button className={key === current ? desc ? 'desc' : 'asc' : undefined} data-key={next} onClick={this.onChangeSortBy.bind(this)}>{key}</button></th> : <th>{key}</th>;
  }

  render() {
    if (!this.state.data) {
      return (
        <aside>
          Loading...
        </aside>
      );
    }
    return (
      <section className="Followings">
        <h1>WhatTheChain?! (pre-alpha)</h1>
        <h2>Followings</h2>

        <div id="filters">
          <ul>
            {Object.entries(this.state.query.filter || {}).map(([k, v], key) => this.renderFilter(key, k, v))}
          </ul>
          <button onClick={this.onAddFilter.bind(this)}>Add</button>
        </div>
        <table>
          <thead>
          <tr>
            {this.renderTh('followed_at_min', true)}
            {this.renderTh('flag', true)}
            {this.renderTh('category', true)}
            {this.renderTh('status', true)}
            {this.renderTh('follower', true)}
            {this.renderTh('followee', true, 2)}
            {this.renderTh('name', true)}
            {this.renderTh('source_created_at', true)}
            {this.renderTh('relevant_followers_count', true)}
            {this.renderTh('followers_count', true)}
            <th>description</th>
          </tr>
          </thead>
          <tbody>
            {this.state.data.map((pos, key) => this.renderFollowing(key, pos))}
          </tbody>
        </table>
        <div id="pagination">
          {this.state.data.length < this.state.query.size ? undefined : <button className="more" onClick={this.loadMore.bind(this)}>More</button>}
        </div>
      </section>
    );
  }
}
