const { REACT_APP_API_URL } = process.env;

export async function load() {
  let controller = new AbortController();
  controller.data = await fetch(`${REACT_APP_API_URL}/followings${window.location.search}`, { signal: controller.signal }).then(res => {
    controller.sort = (res.headers.get('X-Sort') || '').split(',');
    return res.json();
  });
  return controller;
}

export async function set(key, val) {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  const controller = new AbortController();
  let headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', 'Basic ' + btoa(`${username}:${password}`));
  controller.data = await fetch(`${REACT_APP_API_URL}/accounts/${key}`, { method: 'PUT', headers, body: JSON.stringify(val) });
  if (controller.data.status === 401) {
    const err = new Error('Unauthorized');
    err.payload = controller.data;
    throw err;
  }
  return controller;
}
