import React, { Component } from 'react';

export default class App extends Component {
  render() {
    return (
      <div className="Home">
        <h1>WhatTheChain?! (pre-alpha)</h1>
        <ul>
          {/*<li><a href="/markets">Markets</a></li>*/}
          {/*<li><a href="/projects">Projects</a></li>*/}
          <li><a href="/accounts">Accounts</a></li>
          <li><a href="/followings">Followings</a></li>
        </ul>
      </div>
    );
  }
}
