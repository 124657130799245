import { Component, Fragment } from 'react';
import Decimal from 'decimal.js';
import { load } from './lib/positions.js';
import './Positions.css';

export default class Positions extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.market !== this.props.market) {
      this.load();
    }
  }

  async load({ sort, market } = {}) {
    this.request = await load({ sort: sort || this.props.sort, market: market || this.props.market });
    this.setState({ data: this.request.data });
    if (this.props.selected) {
      const selected = this.request.data.find(pos => pos.id === Number(this.props.selected));
      this.setState({ selected });
      this.props.onSelect(selected);
    }
    this.request = null;
  }

  componentWillUnmount() {
    if (this.request) {
      this.request.abort();
    }
  }
  onCurrentMarketOnly() {
    this.props.onPushState(undefined, { currentMarketOnly: !this.props.currentMarketOnly });
  }

  onSelectPosition(el) {
    const selected = this.state.data.find(pos => pos.id === Number(el.currentTarget.value));
    this.setState({ selected });

    const market = `${selected.asset_base}_${selected.asset_quote}`;
    const position = selected.id;
    const from = new Date(new Date(selected.opened_at).getTime() - 60 * 60 * 24 * 10 * 1000).toISOString();
    const to = new Date(new Date(selected.closed_at).getTime() + 60 * 60 * 24 * 10 * 1000).toISOString();

    this.props.onPushState(market, { position, from, to });
    this.props.onSelect(selected);
  }

  async onChangeSortBy(el) {
    const sort = el.currentTarget.value;
    this.load({ sort });
    this.setState({ sort });
    this.props.onPushState(undefined, { sort });
  }

  renderOption(key, pos) {
    const profitPercentage = pos.trade_type === 'long' ? Decimal(pos.price_exit).dividedBy(pos.price_entry).minus(1) : Decimal(1).minus(Decimal(pos.price_exit).dividedBy(pos.price_entry));
    const value = `${pos.asset_base}/${pos.asset_quote} ${profitPercentage.times(100).toFixed(2)}% [${pos.id}] ${pos.net_profit}`;
    return <option key={key} value={pos.id} className={pos.net_profit > 0 ? 'positive' : 'negative'} title={value}>{value}</option>
  }

  renderOptionGroup(data, title) {
    return (
      <optgroup label={title}>
        {data.map((pos, key) => this.renderOption(key, pos))}
      </optgroup>
    );
  }

  render() {
    if (!this.state.data) {
      return (
        <aside>
          Loading...
        </aside>
      );
    }
    return (
      <aside>
        <input type="checkbox" id="onlyThis" value="1" onChange={this.onCurrentMarketOnly.bind(this)} checked={this.props.currentMarketOnly || false} /> <label htmlFor="onlyThis">Current market only</label><br />
        <label htmlFor="sortBy">Sort by</label>
        <select id="sortBy" onChange={this.onChangeSortBy.bind(this)} defaultValue={this.props.sort}>
            <option value="id">ID (asc)</option>
            <option value="-id">ID (desc)</option>
            <option value="profit">Profit (asc)</option>
            <option value="-profit">Profit (desc)</option>
        </select>
        <label htmlFor="positionSelect">Winners</label>
        <select id="positionSelect" onChange={this.onSelectPosition.bind(this)} defaultValue={this.state.data.find(({ id }) => id === Number(this.props.selected)) ? Number(this.props.selected) : null} size="200">
          {(this.props.sort || '').includes('profit') ?
            <Fragment>
              {this.renderOptionGroup(this.state.data.filter(pos => pos.net_profit > 0), 'Winners')}
              {this.renderOptionGroup(this.state.data.filter(pos => pos.net_profit <= 0), 'Losers')}
            </Fragment>
          :
            <Fragment>
              {this.state.data.map((pos, key) => this.renderOption(key, pos))}
            </Fragment>
          }
        </select>
        <label htmlFor="tradeInfo">Selected</label>
        {this.state.selected ?
          <div id="tradeInfo">
            Profit: <strong>{this.state.selected.profit}</strong> BTC, <strong>{((1 - (this.state.selected.price_entry / this.state.selected.price_exit))*100).toFixed(2)}%</strong><br />
            Outcome: <strong>{this.state.selected.outcome}</strong><br />
            Entry: <strong>{this.state.selected.price_entry}</strong><br />
            Exit: <strong>{this.state.selected.price_exit}</strong><br />
            Opened: <strong>{this.state.selected.opened_at.replace('T', ' ').replace('.000Z', '')}</strong><br />
            Closed: <strong>{this.state.selected.closed_at.replace('T', ' ').replace('.000Z', '')}</strong><br />
            Trade length: {Number((new Date(this.state.selected.closed_at).getTime() - new Date(this.state.selected.opened_at).getTime()) / 1000)} seconds
          </div> :
          <div id="tradeInfo">-</div>
        }
      </aside>
    );
  }
}
